/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
//import './styles/app.css';

// start the Stimulus application
//import './bootstrap';

import $ from 'jquery';

// utils
const currency = (amount) => {
    return new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK' }).format(
        amount,
    );
}

const number = (number) => {
    return new Intl.NumberFormat('cs-CZ', {  minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
        number,
    );
}

const initMobileNavigation = () => {

    let $body = $('body');

    $body.on('click', '#mobile-menu .submenu-helper', function () {
        let $chevron = $(this);

        console.log($chevron);

        let $li = $chevron.closest('li');

        if ($li.hasClass('opened')) {
            $li.removeClass('opened');
        } else {
            $li.addClass('opened');
        }

        return false;
    });

}

// @todo prepocet formulare nahradniho plneni pro web sezev - treba presunout do klientskeho JS
let $formContainer = $('#substitute-fulfillment-form-container');
const calcSubstituteFulfillment = () => {
    let numberOfEmployees = $formContainer.find('#substitute_fulfillment_number_of_employees').val();

    const mandatoryShare = parseFloat($formContainer.data('mandatory-share'));
    const averageSalary = parseFloat($formContainer.data('average-salary'))
    console.log({
        mandatoryShare: mandatoryShare,
        averageSalary: averageSalary
    });

    if(/^\d+$/.test(numberOfEmployees) === false) {
        numberOfEmployees = 0;
    }

    numberOfEmployees = parseInt(numberOfEmployees);
    if (isNaN(numberOfEmployees)) {
        numberOfEmployees = 0;
    }

    if(numberOfEmployees < 25) {
        numberOfEmployees = 0;
    }

    let numberOfDisabledPersons = (numberOfEmployees / 100) * mandatoryShare;
    $formContainer.find('#substitute_fulfillment_number_of_disabled_persons').val(number(numberOfDisabledPersons));

    let substituteFulfillment = 7 * averageSalary * numberOfDisabledPersons;
    $formContainer.find('#substitute_fulfillment_substitute_fulfillment').val(currency(substituteFulfillment));

    let fee = 2.5 * averageSalary * numberOfDisabledPersons;
    $formContainer.find('#substitute_fulfillment_fee').val(currency(fee));

    let yourSavings = fee / 12;
    $formContainer.find('#substitute_fulfillment_your_savings').val(currency(yourSavings));

}

$(document).ready(function () {
    initMobileNavigation();

    // @todo prepocet formulare nahradniho plneni pro web sezev - treba presunout do klientskeho JS
    calcSubstituteFulfillment();
    $formContainer.on('keyup', '#substitute_fulfillment_number_of_employees', () => {
        calcSubstituteFulfillment();
    })
});